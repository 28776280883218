.neon-purple {
    color: #fff;
    text-shadow:
      0 0 7px #9c27b0,
      0 0 10px #9c27b0,
      0 0 21px #9c27b0,
      0 0 42px #9c27b0,
      0 0 82px #9c27b0,
      0 0 92px #9c27b0,
      0 0 102px #9c27b0,
      0 0 151px #9c27b0;
  }

  .buttonMenu {
    color: #FFF; /* Neon white-like color */
    font-weight: bold;
    text-decoration: underline;
    font-size: 1.2rem; /* Larger text */
  }
  
  .buttonMenu:hover {
    background-color: transparent; /* Maintain transparent background on hover */
    text-decoration: none; /* Optional: Remove underline on hover if desired */
  }