.neon-purple {
    color: #fff;
    text-shadow:
      0 0 7px #9c27b0,
      0 0 10px #9c27b0,
      0 0 21px #9c27b0,
      0 0 42px #9c27b0,
      0 0 82px #9c27b0,
      0 0 92px #9c27b0,
      0 0 102px #9c27b0,
      0 0 151px #9c27b0;
  }